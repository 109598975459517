import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import InnerHTML from 'dangerously-set-html-content'

function ContactForm(){
    const data = useStaticQuery(graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`)
    const WebsiteContent = data.allDataJson.edges[0].node
    const jobber = `
    <div id="1e1bef19-99b9-42c5-ac2f-51f14a088a7a"></div>
<link rel="stylesheet" media="screen" href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css" />
<script src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js" clienthub_id="1e1bef19-99b9-42c5-ac2f-51f14a088a7a" form_url="https://clienthub.getjobber.com/client_hubs/1e1bef19-99b9-42c5-ac2f-51f14a088a7a/public/work_request/embedded_work_request_form"></script>
    `


    return(
        <div id={"contact-form"} className={"flex flex-col justify-center items-center flex-wrap dark:bg-gray-900 dark:text-white transition duration-500"}>
            <div className="max-w-2xl my-8 p-8 px-8 max-w-[40rem] border-2 border-main-color-500 rounded">
                <h1 className={"text-center text-4xl"}><span className={"text-main-color-500"}>Fill out</span> the form below to get your free quote!</h1>
                <h2 className={"text-center text-xl mb-12"}>or an answer to any questions you might have</h2>
                <InnerHTML html={jobber} />
            </div>

            <p className={"text-center text-xl text-gray-500 pb-10"}>You can also reach us directly</p>
            <div className={"flex flex-row flex-wrap gap-10 pb-20 justify-center"}>
                <a href={"mailto:" + WebsiteContent.Contact_Details.Email} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Email Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Email}</p>
                </a>
                <a href={"tel:" + WebsiteContent.Contact_Details.Phone_Without_Formatting} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Call Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Phone}</p>
                </a>
                <a href={"sms:" + WebsiteContent.Contact_Details.Phone_Without_Formatting + ";?&body=I%27m%20interested%20in%20getting%20a%20quote%2C%20please%20contact%20me"} className={"p-5 border-4 border-main-color-500 rounded hover:translate-y-2 hover:border-main-color-200 duration-150 group"}>
                    <p className={"text-center text-gray-500 text-xl"}>Click here to:</p>
                    <p className={"text-center text-main-color-600 text-3xl font-bold group-hover:text-main-color-300 duration-150"}>Text Us</p>
                    <p className={"text-center text-gray-500 text-xl"}>{WebsiteContent.Contact_Details.Phone}</p>
                </a>
            </div>
        </div>
    )
}

export default ContactForm